import VCIS1 from '../../img/VCIS/vcis_carousel1.jpg';
import VCIS2 from '../../img/carousel/knowledge1.jpg';
import VCIS4 from '../../img/VCIS/VCIS41.jpg';
import VCIS5 from '../../img/VCIS/VCIS5.jpg';
import VCIS6 from '../../img/VCIS/VCIS62.jpg';
import VCIS7 from '../../img/VCIS/VCIS72.jpg';
import VCIS8 from '../../img/VCIS/VCIS8.jpg';
import VCIS9 from '../../img/VCIS/VCIS91.jpg';
import VCIS10 from '../../img/VCIS/VCIS103.jpg';
import VCIS11 from '../../img/VCIS/VCIS113.jpg';
import VCIS12 from '../../img/VCIS/VCIS122.jpg';
import VCIS13 from '../../img/VCIS/VCIS13.jpg';
const slides = [
  // {
  //   image: eye,
  //   title: '',
  //   description: "",
  //   position: 'slideVCISSPos',
  //   link: '',
  //   button: 'no',
  //   document: 'no',
  //   overlay: 'no'
  // },
  {
    image: VCIS1,
    title: 'VCIS',
    description: "Geo-Smart approach and data sources can help generate digital footprints, trajectory patterns, and location behavioral models.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS2,
    title: "in'VCIS Technology & Data Management:",
    description: "Poor data quality can cost businesses millions, but VCIS can help mitigate risks and reduce overall costs.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS4,
    title: "VCIS Geospatial Analysis revolutionizes fraud, corruption, and financial crime prevention.",
    description: "Regulators face challenges as they chase evolving financial crimes, with over 50% acknowledging issues related to emerging technologies, digital currencies, data privacy, and geopolitics.",
    position: 'slideVCISGeoPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS5,
    title: (
        <>Big data isn’t about <br/> bits, but talent.</>),
    description: "Data is the foundation of information-driven megatrends.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS6,
    title: "in'VCIS Cross Border Preventions",
    description: "VCIS helps in predicting events, analyzing Big Data, and protecting potential targets beyond LEA limits.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS7,
    title: (
        <>
        
        in'VCIS Biometric
        <br/>
        Security System                        
        </>
    ),
    description: "Our Biometric system secures passwords and sensitive data with unique physical traits.",
    position: 'slidebioPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS8,
    title: (
        <>
        
        In'VCIS
        <br/>
        Intelligence System                        
        </>
    ),
    description: "Undercover organizations pose a significant threat to national security.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS9,
    title: (
        <>
        in'VCIS
        <br/>
        Architecture, Kernel
        <br/>
        & Core Element                        
        </>
    ),
    description: "VCIS app provides fast, secure, and cloud-based analytics to turn data into actionable insights.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS10,
    title: (
        <>
        in'VCIS Changes the Way you see and solve problems                  
        </>
    ),
    description: "VCIS app provides fast, secure, and cloud-based analytics to turn data into actionable insights.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS11,
    title: "KYC",
    description: "in'KYC allows you to assess customer risks; meet regulatory requirements and improve overall customer relationships while providing cost-effective to identify and prevent financial crimes.",
    position: 'slideKYCPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS12,
    title: 'VCIS Consumer Intelligence System',
    description: "By employing the power of Geospatial data retailers can precisely target their customers, deliver personalized experiences and drive sales like never before.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  {
    image: VCIS13,
    title: 'VCIS is driving growth and success',
    description: "VCIS allows retailers to create location-based promotions, where geospatial marketing tools provide retailers with valuable insights.",
    position: 'slideVCISSPos',
    link: '/ai',
    button: 'no',
    document: 'no',
    overlay: 'yes'
  },
  // {
  //   image: VCIS13,
  //   title: 'VCIS is driving growth and success',
  //   description: "VCIS allows retailers to create location-based promotions, where geospatial marketing tools provide retailers with valuable insights.",
  //   position: 'slideVCISSPos',
  //   link: '/ai',
  //   button: 'no',
  //   document: 'no',
  //   overlay: 'yes'
  // },
  
//   {
//     image: Fatf0,
//     video:CarouselVideo,
//     title: '',
//     description: (
//       <>
//         <h1 className='text-white'><strong>FATF QUANTUM LEAP</strong></h1>
//         <h2 className='text-white'>Transforming Compliance Standards The VALOORES Blueprint Crafting Compliance & Investigation Excellence.</h2>
//       </>
//     ),
//     position: 'slidePos',
//     link: '/Fatf',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
//   {
//     image: Albert,
//     title: '',
//     description: (
//       <>
//         We can't solve problems by using <br/> the same kind of thinking we used <br/> when we created them. <br/>The whole of science is nothing more <br/> than a refinement of everyday thinking <br />
//         <span style={{ fontStyle: 'italic' }}>~ Albert Einstein ~</span>
//       </>
//     ),
//     position: 'slidePos',
//     link: '/banking/inAnalytics',
//     button: 'no',
//     document: 'no',
//     overlay: 'no'
//   },
//   {
//     image: Retail,
//     title: 'Retail and Supply Chain',
//     description: 'Valoores uses supply chain technologies and advanced retail services for responding to market needs.',
//     position: 'slidePos',
//     link: '/Geospatial/retail-data',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
  
  
//   {
//     image: Knowledge,
//     title: "in'VCIS Technology & Data Management",
//     description: "Poor data quality can cost businesses millions, but Valoores can help mitigate risks and reduce overall costs.",
//     position: 'slidePos',
//     // link: 'https://indatacrowd.ai/',
//     link: '/VCIS1/',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
//   {
//     image: BigData,
//     video:'',
//     title: (
//       <>
//         Big Data Isn't About<br /> Bits, But Talent.
//       </>
//     ),
//     description: (
//       <>
//         A wealth of information<br /> creates a poverty of attention.<br /> With big data, comes big<br /> responsibility to derive insight,<br/> make decisions and drive value.
//       </>
//     ),
//     position: 'slideBigPos',
//     link: '/ai',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
//   {
//     image: Government,
//     title: 'Government',
//     description: 'Valoores solutions facilitate the use of information and communication technologies to provide and improve public-sector services',
//     position: 'slidePos',
//     link: '/solutions/gov',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
//   {
//     image: Financial,
//     title: 'Financial Services',
//     description: 'Valoores Delivers intelligent financial solutions and productivity-enhancing case management tools for financial institutions.',
//     position: 'slidePos',
//     link: '/banking',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
//   {
//     image: Multimedia,
//     title: (
//       <>
//         <div style={{ color: '#fff' }}>
//           Multichannel Communication & Multimedia
//         </div>
//       </>
//     ),
//     description: (
//       <>
//         <div style={{ color: '#fff' }}>
//           We offer a comprehensive suite of services that enable organizations to connect, captivate, and collaborate effectively in today's dynamic digital landscape.
//         </div>
//       </>
//     ),
//     position: 'slideMultiPos',
//     link: '/Multimedia',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
//   {
//     image: v20,
//     title: '',
//     description: 
//     (
//       <>
//         <div className='slide10Font'>
//           VALOORES announces the
//           Next Generation v20 Ecosystem<br />
//           That marches at the same drum
//           with the latest Digital Transformation guidelines
//         </div>
//       </>
//     ),
//     position: 'slideEcoPos',
//     // link: 'https://valoores.com/v20.php',
//     link: '/V21',
//     button: 'yes',
//     document: 'no',
//     overlay: 'yes'
//   },
//   {
//     image: Slide7,
//     title: (
//       <>
//         <div style={{ fontSize: '2.5rem', lineHeight: '2.8rem', fontWeight: "500" }}>
//           You have the VALUE,<br />
//           we bring you the<br /> <span style={{}}>Added VALUE</span><br /><br />
//           it's VALOORES!
//         </div>
//       </>
//     ),
//     description: '',
//     position: 'slidePos',
//     link: 'https://indatacrowd.ai/',
//     button: 'no',
//     document: 'no',
//     overlay: 'yes'
//   },
  // {
  //   image: SlideLogo,  
  //   title: '',
  //   description: '',
  //   position: 'slideFirst',
  //   link: '/banking/inAnalytics',
  //   button: 'yes',
  //   document: 'no',
  //   overlay: 'yes'
  // },
  // {
  //   image: Slide001,  
  //   title: '',
  //   description: (<>
  //     The 2023 Award winner Nehmé Y. Taouk (CEO & Founder, VALOORES, Lebanon) <br /> <br /> Advocacy and public policy engagement. Fostering social cohesion and inter-group dialogue and relationship-building in the workplace, marketplace and local community. 
  //   </>),
  //   position: 'slideTeam',
  //   link: '/banking/inAnalytics',
  //   button: 'no',
  //   document: 'no',
  //   overlay: 'yes'
  // },
  
//   {
//     image: ValooresFam,
//     title: '',
//     description: (
//       <>
//           <h2 className='mb-3 text-white' style={{fontSize: '1.5rem'}}>The company is proud to have received <br /> <span style={{fontStyle: 'italic'}}>The Global Business and Intercultural<br /> peace award - 2023</span></h2>
//           <h4 className='text-white'>we attribute our success to the hard work and dedication of our exceptional team, with their belonging and accountability to deliver the<br /> best always on time.</h4>
//       </>
//       ),
//     position: 'slidePos',
//     link: '/banking/inAnalytics',
//     button: 'no',
//     document: 'no',
//     overlay: 'yes'
//   },
];





export default slides;